import { IArtisanComponentCmp } from './IArtisanComponentCmp';
import { makeLink } from '@/utils/Link';
import dynamic from 'next/dynamic';

const ArtisanComponent: React.FC<IArtisanComponentCmp> = props => {
  const { template, artisanSlug, link } = props.fields;

  const Components: { [key: string]: any } = {
    ArtisanCarousel: dynamic(
      () => import(`@/components/organisms/ArtisanCarousel/ArtisanCarousel`)
    ),
    ArtisanWithProducts: dynamic(
      () =>
        import(`@/components/organisms/ArtisanWithProducts/ArtisanWithProducts`)
    ),
  };

  let content = null;

  let url = null;
  let target = null;
  let label = null;
  if (link && link.fields) {
    if (link.fields.href) {
      url = makeLink(link.fields.type, link.fields.href, null);
    }
    if (link.fields.target) {
      target = link.fields.target;
    }
    if (link.fields.text) {
      label = link.fields.text;
    }
  }

  let DynamicComponent = null;

  switch (template) {
    case 'carousel':
      DynamicComponent = Components.ArtisanCarousel;
      break;
    case 'card_with_products':
      DynamicComponent = Components.ArtisanWithProducts;
      break;
  }

  if (DynamicComponent) {
    content = (
      <DynamicComponent
        artisan_slug={artisanSlug}
        url={url}
        target={target}
        label={label}
      />
    );
  }

  return <>{content}</>;
};

export default ArtisanComponent;
